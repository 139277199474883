.loader {
  display: flex;
  height: 70px;
  width: 50px;
  align-items: center;
  justify-content: space-between;
}
.loaderSmall {
  height: 25px;
  width: 20px;
}
.loader span {
  width: 28%;
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loader span:nth-child(1) {
  animation-delay: -0.3s;
}
.loader span:nth-child(2) {
  animation-delay: -0.15s;
}
.loader span:nth-child(3) {
  animation-delay: 0;
}
@keyframes loader {
  0% {
    height: 60%;
  }
  20% {
    height: 100%;
  }
  50%,
  100% {
    height: 60%;
  }
}
