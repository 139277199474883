@charset "utf-8";

// Bulma theme customization
$family-sans-serif: "Nunito", sans-serif;
$primary: #3d76a5;
$primary-dark: #316188;
$radius-large: 3px;

$feature: #2c4e6a; // very dark for feature background

@import "~bulma/sass/utilities/_all";
