@import "../../styles/variables.scss";

.spacerRow {
  background-color: white !important;
  td {
    border: none;
    line-height: 4px;
  }
}

.detailsTable {
  th,
  td {
    border: none;
    &:first-child {
      padding-left: 0;
    }
  }
}
