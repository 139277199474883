@import "../../styles/variables.scss";

.AssessmentControl {
  flex-grow: 1;
  flex-basis: 0;
}

.AssessmentControlsContainer {
  min-width: 400px;
  align-self: center;
  @include mobile {
    min-width: auto;
    width: 100%;
  }
}

.AssessmentButtonGroup {
  gap: 0.7rem;
}

.WrappedAssessmentButton {
  white-space: unset;
  line-height: 1.2;
  height: 100%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
