@import "../../styles/variables.scss";

.menuButton {
  @include reset;
  border-radius: 2px;
  color: white;
  display: block;
  width: 100%;
  padding: 0.5em 0.75em;
  text-align: left;
  cursor: pointer;

  &:hover:not(.menuButtonActive) {
    background-color: $primary-dark;
  }
}

.menuButtonActive {
  background-color: $primary;
}
