@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import "./variables.scss";
@import "bulma";

html,
body,
#root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.is-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.is-fullheight {
  height: 100%;
}

.is-fullwidth {
  width: 100%;
}

.is-fullwidth-mobile {
  @include mobile {
    width: 100%;
  }
}

.has-border {
  border: 1px solid $border;
}

.has-border-top {
  border-top: 1px solid $border;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  z-index: unset;
}

.has-scroll-y {
  overflow-y: auto;
}

.dropdown-item.is-hoverable:hover {
  background-color: $grey-lighter;
}

.navbar-menu {
  &,
  &.is-active {
    display: flex;
  }
  flex-direction: column;
  justify-content: space-between;
  @include touch {
    transition: all 250ms ease-in-out;
    position: absolute;
    max-width: 500px !important;
    top: 0;
    left: 0;
    bottom: 0;
    margin-top: 58px;
    &:not(.is-active) {
      margin-left: -100%;
    }
  }
}

.navbar-item {
  display: flex;
  align-items: center;
}

.dropdown-menu {
  min-width: 16rem;
}

.modal-content,
.modal-card {
  min-height: 280px;
  @include mobile {
    width: 97%;
  }
}

.modal-card-head {
  border: none;
}

.modal-card-foot {
  padding: 15px;
}

.button {
  transition: all 70ms ease;
  font-weight: bold;
  border-bottom-width: 3px;

  &:not(:disabled) {
    &:focus:not(.is-primary, .is-danger, .is-ghost) {
      box-shadow: none;
      border-color: $grey-light;
      &:active {
        border-color: $grey;
      }
    }

    &:active {
      transform: translate(0, 2px);
    }
  }
}

.navbar-item {
  padding: 0.5rem;
}

.tag:not(body).is-orange {
  background-color: $orange;
  color: white;
}

.tag:not(body).is-danger-dark {
  background-color: $danger-dark;
  color: white;
}

.navbar-burger {
  height: auto;
  min-height: 3.25rem;
}

.has-background-feature {
  background-color: $feature;
}

.modal {
  display: flex !important;
  transform: translate(9999px);
  visibility: hidden;
}

.modal-background {
  transition: all 150ms ease-in-out;
  opacity: 0;
}

.modal-card {
  opacity: 0;
  transform: scale(0.9);
  transition: all 150ms ease-in-out;
}

.modal.is-active {
  visibility: visible;
  transform: translate(0px);
  .modal-card {
    opacity: 1;
    transform: scale(1);
  }

  .modal-background {
    opacity: 1;
  }
}

.field.is-grouped > .control:not(:last-child) {
  margin-right: 0;
}

// ios fixes
input::-webkit-date-and-time-value {
  text-align: left;
}

* {
  -webkit-tap-highlight-color: transparent; // disable flash on click in mobile web
}
