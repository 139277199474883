@import "../../styles/variables.scss";

.canvasButton {
  border-width: 1px !important;
  padding: 1em;
}

.isSelected,
.isSelected:focus {
  outline: 2px solid $grey-light !important;
}
