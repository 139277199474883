@import "../../styles/variables.scss";

.menu {
  max-width: 365px;
  width: 90%;
}

.navbar {
  flex-basis: 0;
  border-bottom: 1px solid $border;
  z-index: 10;
  position: relative;
}

.menuDropdownButton {
  background-color: transparent;
  color: white;
  border-radius: 50% 50%;
  border: none;
}

.menuDropdownButton:hover,
.menuDropdownButton:focus {
  color: white;
}

.menuDropdownButton:focus {
  border: 1px solid;
}
