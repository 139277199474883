.AssessmentImageContainer {
  max-width: 500px;
  max-height: 50%;
  margin: 2rem 0;
  align-self: center;
  text-align: center; /* centers image on mobile*/
}

.AssessmentImage {
  max-width: 100%;
  max-height: 100%;
}

.AssessmentImageLoading {
  width: 0;
  height: 0;
}
